/* You can add global styles to this file, and also import other style files */

:root{
    --vetsy-dp: #563B97;
    --vetsy-lp: #7F4BB6;
}
html {
  height: 100%;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
}

body {
  height: 100%;
  background-color: #edeff0!important;;
}

.body2b-b{
  font-family: "Open Sans";
  font-weight: 700;
  color: #000;
  font-size: 16px;
}


button{
  cursor: pointer;
}
button:focus {
  outline:#1FBCB3!important;
}
input:focus{
  outline:#1FBCB3!important;
}
.veteva-icon{
  color:#0C7581;
}

.p-text-semibold {
  font-weight: 500;
}

.modal-content{
  border-radius:10px;
  border: none;
}
.col-0-nset{
  flex: 0;
}

.btn-secondary-delete {
  background-color: red!important;
  border: 0px;
  color:white;
  font-family: 'Open Sans';
  font-weight: 500;
  border-radius: 100em;
  font-size: 14px;
  padding: 2px 42px;
  max-height: 27px;
  margin-top: calc((50px - 27px) / 2)
}

.btn-secondary-gray {
  background-color: #E5E5E5;
  border: 0px;
  font-family: 'Open Sans';
  font-weight: 400;
  border-radius: 16px;
  font-size: 16px;
  padding: 6px 24px;
  color:#008B6B;
}

.header-container-page{
  background-color: #fff;
  border-bottom: 1px solid #CACFCD;
}

.yes-mobile{
  display: block!important;
}

.header-text-page {
  font-size: 36px;
  line-height: 120%;
  text-align: center;

  color: #000000!important;
}
.veteva-table-header{
  color:white;
  background-color: #0C7581;
}

.btn-veteva-admin {
  background-color:#00BAB1!important;
}

.btn-veteva-admin-edit {
  background-color:#0C7581!important;
}

.btn-veteva-secondary {
  background-color:#00BAB1;
  border: 0px!important;
  font-family: 'Open Sans'!important;
  font-weight: 400!important;
  border-radius: 16px!important;
  font-size: 16px!important;
  padding: 6px 24px!important;
  color:white!important;
}
.btn-veteva-secondary-delete {
  background-color:red!important;
  border: 0px!important;
  font-family: 'Open Sans'!important;
  font-weight: 400!important;
  border-radius: 16px!important;
  font-size: 16px!important;
  padding: 6px 24px!important;
  color:white!important;
}
.btn-veteva-secondary-edit {
  background-color:#0C7581;
  border: 0px!important;
  font-family: 'Open Sans'!important;
  font-weight: 400!important;
  border-radius: 16px!important;
  font-size: 16px!important;
  padding: 6px 24px!important;
  color:white!important;
}

.notification-tab{
  font-size: 10px;
  background-color: orange!important;
  color: white;
  margin-left: -20px;
  height: 20px;
  margin-top: 4px;
  border-radius: 50%;
  z-index:2;
  width: 20px;

}

.textarea-space {
  white-space: pre-wrap!important;
}

.header-page-container{
  background-color: #fff;
  border-bottom: 1px solid #CACFCD;
  min-height:100px;
}
.att-icon{
  font-size: 60px;
}
.word-icon{
  color: #295394;
}
.xls-icon{
  color: #1F6E43;
}
.pdf-icon{
  color:#D41517;
}
.form-control {
  width: 100%;
  height: 48px;
  border: 0px solid white!important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: 2px solid #bebebe!important;
  background-color: #f9f9f9!important;
  color:#4a4a4a;
  font-weight:600;
}
.form-control::placeholder {
  font-weight: normal;
  font-size: 16px;
  color: #bebebe;
  font-weight: 600;
}

.login-btn{
  
  width:100%;
  border: 0px;
  font-family: 'Open Sans';
  font-weight: 600!important;
  border-radius: 30px;
  font-size: 18px!important;
  color:white;
  padding: 13px!important;
}



.logo-img{
  width:350px;
}
@media(max-width:768px){
  .logo-img{
    width:280px;
  }
  .body1 {
    font-size: 12px!important;
  }
  .mobile-scroll{
    overflow-x: scroll;
  }
}

@media(max-width:400px){
  .no-mobile{
    display: none!important;
  }

}